import React from 'react';
import PartnerLayout from '../components/Containers/PartnerLayout';

const ServicePartners = () => {
  return (
    <PartnerLayout partnerType="service">
      <h4>Our network includes:</h4>
    </PartnerLayout>
  );
};

export default ServicePartners;
